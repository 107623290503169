import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import ExcalidrawApp from "./App";
import { registerSW } from "virtual:pwa-register";

window.__EXCALIDRAW_SHA__ = import.meta.env.VITE_APP_GIT_SHA;
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);
registerSW();

const urlParams = new URLSearchParams(window.location.search);

const applicationId = urlParams.get("applicationId");
const _roomKey = urlParams.get("roomKey");
const username = urlParams.get("username");
const theme = urlParams.get("theme") || "dark";
const userImage = urlParams.get("userImage") || undefined;
const userBackgroundColor = urlParams.get("color");
const isViewMode = urlParams.get("isViewMode") === "true";

root.render(
  <StrictMode>
    {applicationId && _roomKey && username && theme && userBackgroundColor && (
      <ExcalidrawApp
        roomId={applicationId}
        roomKey={_roomKey}
        username={username}
        theme={theme}
        userImage={userImage}
        userBackgroundColor={"#" + userBackgroundColor}
        isViewMode={isViewMode}
      />
    )}
  </StrictMode>,
);
